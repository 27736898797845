export default {
  state: () => ({
    scrollYDirection: 'UP',
    isScrollingDown: false,
    currentScroll: 0,
  }),

  getters: {
    isScrollDown: (state) => {
      return state.scrollYDirection === 'DOWN'
    },
    getIsScrollingDown: (state) => {
      return state.isScrollingDown;
    },
    getCurrentScroll: (state) => {
      return state.currentScroll;
    },
  },
  
  mutations: {
    set(state, direction) {
      state.scrollYDirection = direction
    },
    setIsScrollingDown(state, payload) {
      state.isScrollingDown = payload;
    },
    setCurrentScroll(state, payload) {
      state.currentScroll = payload;
    }
  }
}